/* devanagari */
@font-face {
  font-family: '__Poppins_1562c7';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/87c72f23c47212b9-s.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_1562c7';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/591327bf3b62a611-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_1562c7';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/916d3686010a8de2-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: '__Poppins_1562c7';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/839135d04a097cea-s.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_1562c7';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/370d1cc320ec5619-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_1562c7';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7777133e901cd5ed-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: '__Poppins_1562c7';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/3828f203592f7603-s.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_1562c7';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/10939feefdad71be-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_1562c7';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d869208648ca5469-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: '__Poppins_1562c7';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/20b8b8f6f47c1e10-s.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_1562c7';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f93b79c1ea023ab6-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_1562c7';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/9a881e2ac07d406b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_1562c7';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_1562c7 {font-family: '__Poppins_1562c7', '__Poppins_Fallback_1562c7';font-style: normal
}.__variable_1562c7 {--font-poppins: '__Poppins_1562c7', '__Poppins_Fallback_1562c7'
}

